export enum RiskProfile {
  UNKNOWN = "UNKNOWN",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}

export interface RiskProfileSettingValue {
  description?: string
  color?: string
  score?: number
}

export interface RiskProfileSetting {
  // These properties are all required for the Global RiskSetting,
  // but not on organization level
  [RiskProfile.LOW]?: RiskProfileSettingValue
  [RiskProfile.MEDIUM]?: RiskProfileSettingValue
  [RiskProfile.HIGH]?: RiskProfileSettingValue
  [RiskProfile.VERY_HIGH]?: RiskProfileSettingValue
}

export interface RiskTicketSetting {
  period?: number
  lessThan2?: number
  between2and4?: number
  between5and10?: number
  moreThan10?: number
}

export interface RiskComponentSettingAge {
  fromAge?: number
  score?: number
}

export interface RiskComponentSettingCondition {
  GOOD?: number
  DECENT?: number
  INSUFFICIENT?: number
  BAD?: number
}

export interface RiskComponentSetting {
  _id?: string
  component: string
  age?: RiskComponentSettingAge
  condition?: RiskComponentSettingCondition
}

export interface RiskCategorySetting {
  _id: string
  assetCategory: string
  components: Array<RiskComponentSetting>
}

export interface RiskSetting {
  _id: string
  organization?: string
  riskProfile?: RiskProfileSetting
  ticket?: RiskTicketSetting
  components: Array<RiskComponentSetting>
  categories: Array<RiskCategorySetting>
}

export const useRiskStore = defineStore("risk", () => {
  const generateRiskScoresForOrganization = async (organizationId: string) => {
    return await useSamApi(`/risk/generate/${organizationId}`, { method: "POST" })
  }

  const generateRiskScoreForAsset = async (organizationId: string, assetId: string) => {
    return await useSamApi(`/risk/generate/${organizationId}/${assetId}`, { method: "POST" })
  }

  const generateAllRiskScores = async () => {
    return await useSamApi(`/risk/generate`, { method: "POST" })
  }

  return {
    generateRiskScoresForOrganization,
    generateRiskScoreForAsset,
    generateAllRiskScores,
  }
})
